html {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: transparent;
  background-color: transparent;
  user-select: none;
  font-family: system-ui;
  align-items: center;
  justify-content: center;
  background-color: gray;
}

#root {
  height: 100%;
  width: 100%;
}

.peer-cell {
  display: block;
  overflow: hidden;
  border-radius: 10px;
  margin: 2px;
  height: 100%;
}

div>video {
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.frontal-camera-video {
  transform: rotateY(180deg);
}

.button-box {
  position: absolute;
  bottom: 10%;
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  opacity: 0;
}

.display-name {
  position: absolute;
  left: 50%;
  padding-bottom: 4px;
  bottom: 0px;
  transform: translate(-50%, 0%);
  color: red;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 16pt;
  font-weight: bold;
  z-index: 100;
}

.track-info {
  position: relative;
  color: whitesmoke;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 8pt;
  line-height: 8pt;
  font-weight: 300;
  z-index: 100;
}

.track-info-container {
  position: absolute;
  width: 100%;
  top: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sent-media-statistics-view {
  position: absolute;
  top: 0px;
  padding: 4px;
  color: whitesmoke;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 8pt;
  line-height: 8pt;
  font-weight: normal;
  z-index: 100;
  text-align: center;
  background-color: #00000078;
}

.video-resolution {
  position: absolute;
  bottom: 0px;
  padding-bottom: 4px;
  padding-left: 4px;
  color: whitesmoke;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 8pt;
  line-height: 8pt;
  font-weight: normal;
  z-index: 100;
  text-align: left;
  text-shadow: 1px 1px black;
}

.statistics-toggle {
  position: absolute;
  bottom: 0px;
  color: whitesmoke;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 8pt;
  line-height: 8pt;
  font-weight: normal;
  z-index: 100;
  text-shadow: 1px 1px black;
  text-align: right;
  box-sizing: border-box;
}

.room-view {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
}

.transport-statistics-view {
  overflow: hidden;
}

.room-members-view {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.grid-items-view-root {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.grid-items-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  max-height: 100%;
  overflow: hidden;
  max-width: calc(var(--width) * var(--cols));
}

.grid-items-cell {
  width: var(--width);
  height: var(--height);
  text-align: center;
  position: relative;
}